import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Upload,
  Checkbox,
} from "antd";
import {
  UploadOutlined,
} from "@ant-design/icons";
import axios from "../../utils/axios";
import { SERVER_URL } from "../../Constants";
import { DndContext, PointerSensor, useSensor } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import DraggableUploadListItem from "../DraggableUploadListItem/intex";

const formItemLayout = null;
const { TextArea } = Input;

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

const AddPostModal = ({ influencer_id, isOpenPostModal, setIsOpenPostModal, setUpdatedPosts }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [fileUploading, setFileUploading] = useState(false);
  const [influencerOptions, setInfluencerOptions] = useState([]);
  const [previewList, setPreviewList] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [isPackage, setIsPackage] = useState(false);
  const [isSkipWaitList, setIsSkipWaitList] = useState(false);
  const [isPined, setIsPined] = useState(false);
  const [isHumanGenerated, setIsHumanGenerated] = useState(false);

  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    const fetchData = () => {
      axios.get(SERVER_URL + "/posts").then(function (res) {
        const { influencers } = res.data;
        if (influencers.length > 0) {
          setInfluencerOptions(
            influencers.map((item) => {
              return {
                label: item.name,
                value: item.id,
              };
            }).filter((item) => item.value === Number(influencer_id))
          );
        }
      });
    };
    fetchData();
  }, []);

  const closeAddPostModal = () => {
    setIsOpenPostModal(false);
    setFileUploading(false);
    setIsEdit(false);
    setIsActive(false);
    setIsPackage(false);
    setIsSkipWaitList(false);
    setIsPined(false);
    setIsHumanGenerated(false);
    setFileList([]);
    setPreviewList([]);
    form.resetFields();
  };

  const handlePostOk = async () => {
    try {
      await form.validateFields().then(async (values) => {
        setFileUploading(true);
        let preview = [];
        const files = fileList ? fileList.filter((file) => !file?.status) : [];
        if (values.price) {
          try {
            const price = Number(values.price);
            if (price < 0) {
              messageApi.error("Please input valid price");
              return;
            }
          } catch (e) {
            messageApi.error("Please input valid price");
            return;
          }
        }

        preview =
          values.preview && values.preview.length > 0
            ? values.preview[0].originFileObj
            : null;
        values.influencer_name = influencerOptions.find(
          (item) => item.value === Number(influencer_id)
        ).label;

        // Create a new FormData instance
        const formData = new FormData();
        formData.append("influencer_id", influencerOptions[0].value);
        formData.append("influencer_name", influencerOptions[0].label);
        formData.append("price", values["price"] ? values["price"] : 0);
        formData.append("discount_price", values["discount_price"] ? values["discount_price"] : 0);
        formData.append(
          "description",
          values["description"] ? values["description"] : ""
        );
        formData.append("is_active", isActive ? 1 : 0);
        formData.append("is_package", isPackage ? 1 : 0);
        formData.append("skip_wait_list", isSkipWaitList ? 1 : 0);
        formData.append("is_pined", isPined ? 1 : 0);
        formData.append("human_generated", isHumanGenerated ? 1 : 0);
        formData.append("tokens", values["tokens"] ? values["tokens"] : 0);
        formData.append("title", values["title"] ? values["title"] : "");

        if (preview) {
          // Add the preview file to the formData instance
          formData.append("preview", preview);
          formData.append("preview_name", preview.name);
        }

        // Add the files to the formData instance
        files.map((file) => {
          formData.append("files", file.originFileObj);
          formData.append("name", file.name);
          formData.append("type", file.type);
          return file;
        });

        if (isEdit) {
          formData.append(
            "post_media_ids",
            JSON.stringify(fileList.map((file) => file.uid))
          );
          formData.append(
            "prev_preview",
            previewList.map((file) => file.url)
          );
          formData.append("post_id", values.id);
          await axios.put(SERVER_URL + "/posts", formData);
        } else {
          await axios.post(SERVER_URL + "/posts", formData);
        }

        messageApi.open({
          type: "success",
          content: "Post saved successfully!",
        });
        setUpdatedPosts(false);
        closeAddPostModal();
      });
    } catch (error) {
      console.log("--ERROR--", error);

      if (error.response) {
        // Try to get the error message from the server otherwise set it to a default message
        const errorMessage =
          error.response.data.error || "Something went wrong!";

        messageApi.open({
          type: "error",
          content: errorMessage,
        });
      }
      setFileUploading(false);
    }
  };

  const onChangeActive = (e) => {
    setIsActive(e.target.checked);
  };

  const onChangePackage = (e) => {
    if (e.target.checked === false) {
      setIsSkipWaitList(false);
    }
    setIsPackage(e.target.checked);
  };

  const onChangeSkipWaitList = (e) => {
    if (e.target.checked === false) {
      setIsSkipWaitList(false);
    }
    setIsSkipWaitList(e.target.checked);
  };

  const onChangePined = (e) => {
    setIsPined(e.target.checked);
  };

  const onChangeHumanGenerated = (e) => {
    setIsHumanGenerated(e.target.checked);
  };

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setFileList((prev) => {
        const activeIndex = prev.findIndex((i) => i.uid === active.id);
        const overIndex = prev.findIndex((i) => i.uid === over?.id);
        return arrayMove(prev, activeIndex, overIndex);
      });
    }
  };

  const sensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 10,
    },
  });

  return (
    <>
      {contextHolder}
      <Modal
        title={isEdit ? "Edit Post" : "Add Post"}
        okText={fileUploading ? "Uploading..." : "OK"}
        open={isOpenPostModal}
        onOk={handlePostOk}
        onCancel={closeAddPostModal}
        width={1000}
        okButtonProps={{ disabled: fileUploading }}
        cancelButtonProps={{ disabled: fileUploading }}
      >
        <Form form={form} name="dynamic_rule" layout="vertical">
          <div style={{ display: "flex", gap: "10px" }}>
            <div style={{ width: "100%" }}>
              <div style={{ color: "red", paddingBottom: "10px", fontWeight: "bold" }}>{influencerOptions[0]?.label}</div>
              <Form.Item {...formItemLayout} name="price" label="Price, $">
                <Input type="number" min={0} placeholder="Please input price" />
              </Form.Item>

              <Form.Item {...formItemLayout} name="discount_price" label="Dicount Price, $">
                <Input type="number" min={0} placeholder="Please input discount price" />
              </Form.Item>

              <Form.Item {...formItemLayout} name="tokens" label="Tokens">
                <Input
                  type="number"
                  min={0}
                  placeholder="Please input tokens"
                />
              </Form.Item>

              <Form.Item {...formItemLayout} name="title" label="Title">
                <Input type="text" placeholder="Please input title" />
              </Form.Item>

              <Form.Item {...formItemLayout} name="slug" label="Slug">
                <Input type="text" disabled={true} />
              </Form.Item>

              <Form.Item {...formItemLayout} name="is_active">
                <Checkbox checked={isActive} onChange={onChangeActive}>
                  Is Active
                </Checkbox>
              </Form.Item>

              <Form.Item {...formItemLayout} name="is_package">
                <div style={{ color: "red" }}>
                  Package product used for wordpress site. Did't show in bonus
                  contetnt section.
                </div>
                <Checkbox checked={isPackage} onChange={onChangePackage}>
                  Is Package
                </Checkbox>
              </Form.Item>

              {isPackage && (
                <Form.Item {...formItemLayout} name="skip_wait_list">
                  <Checkbox
                    checked={isSkipWaitList}
                    onChange={onChangeSkipWaitList}
                  >
                    Is Skip Wait List
                  </Checkbox>
                </Form.Item>
              )}

              <Form.Item {...formItemLayout} name="is_pined">
                <div style={{ color: "red" }}>
                  If pined product show on top. Only one product may be pined.
                </div>
                <Checkbox checked={isPined} onChange={onChangePined}>
                  Is Pined
                </Checkbox>
              </Form.Item>

              <Form.Item {...formItemLayout} name="human_generated">
                <Checkbox
                  checked={isHumanGenerated}
                  onChange={onChangeHumanGenerated}
                >
                  Is Human Generated
                </Checkbox>
              </Form.Item>

              <Form.Item
                {...formItemLayout}
                name="id"
                style={{ display: "none" }}
              >
                <Input type="hidden" placeholder="" />
              </Form.Item>
            </div>

            <div style={{ width: "100%" }}>
              <Form.Item
                name="preview"
                label="Preview Video or Photo (max: 1 video or photo)"
                getValueFromEvent={normFile}
              >
                <Upload
                  name="preview"
                  action={
                    "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                  }
                  listType="picture"
                  fileList={previewList}
                  multiple={false}
                  beforeUpload={() => false}
                  maxCount={1}
                  onChange={(e) => {
                    setPreviewList(e.fileList);
                  }}
                  onRemove={(e) => {
                    setPreviewList([]);
                  }}
                >
                  <Button icon={<UploadOutlined />}>Click to upload</Button>
                </Upload>
              </Form.Item>

              <DndContext sensors={[sensor]} onDragEnd={onDragEnd}>
                <SortableContext
                  items={fileList.map((i) => i.uid)}
                  strategy={verticalListSortingStrategy}
                >
                  <Form.Item
                    name="files"
                    label="Photo (max: 25 photo), Video (max: 1 video, maxLength: 1h)"
                    getValueFromEvent={normFile}
                  >
                    <Upload
                      name="files"
                      action={
                        "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                      }
                      listType="picture"
                      fileList={fileList}
                      multiple={true}
                      beforeUpload={() => false}
                      maxCount={25}
                      onChange={(e) => {
                        setFileList(e.fileList);
                      }}
                      onRemove={(e) => {
                        setFileList(
                          fileList.filter((file) => file.uid !== e.uid)
                        );
                      }}
                      itemRender={(originNode, file) => (
                        <DraggableUploadListItem
                          originNode={originNode}
                          file={file}
                        />
                      )}
                    >
                      <div style={{ color: "red" }}>
                        The first photo should be the same as in the preview
                        field
                      </div>
                      <Button icon={<UploadOutlined />}>Click to upload</Button>
                    </Upload>
                  </Form.Item>
                </SortableContext>
              </DndContext>
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <Form.Item
              {...formItemLayout}
              name="description"
              label="Description"
            >
              <TextArea rows={3} placeholder="Please input description" />
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </ >
  );
};

export default AddPostModal;
