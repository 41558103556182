import { Form, Input } from "antd";
import TextArea from "antd/es/input/TextArea";
import React from "react";

const ModelSettings = (formItemLayout) => {
  const { aiEngine, setAiEngine } = formItemLayout;

  return (
    <>
      <h2>Model Settings:</h2>
      <Form.Item
        {...formItemLayout}
        name="open_ai_timeout"
        label="Open AI Timeout in Seconds (Applies to both link matching and model inference)"
        rules={[
          { required: true, message: "Please input the Open AI Timeout" },
        ]}
      >
        <Input
          type="text"
          min={1}
          placeholder="Please input the Open AI Timeout"
        />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="token_limit"
        label="Token Limit / Sequence Length"
        rules={[{ required: true, message: "Please input the Token Limit" }]}
      >
        <Input type="text" min={1} placeholder="Please input the Token Limit" />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="temperature"
        label="Temprature"
        rules={[{ required: true, message: "Please input the Temperature" }]}
      >
        <Input placeholder="Please input influencer the Temperature" />
      </Form.Item>

      <Form.Item
        {...formItemLayout}
        name="top_p"
        label="top_p"
        rules={[{ required: true, message: "Please input the top p" }]}
      >
        <Input type="text" min={1} placeholder="Please input the top p" />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="top_k"
        label="top_k"
        rules={[{ required: true, message: "Please input the top k" }]}
      >
        <Input type="text" min={1} placeholder="Please input the top k" />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="min_length"
        label="Min length [Does not work for VLLM]"
        rules={[{ required: true, message: "Please input the Min Length" }]}
      >
        <Input type="text" min={1} placeholder="Please input the Min Length" />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="max_new_tokens"
        label="Max New Tokens"
        rules={[{ required: true, message: "Please input the Max new tokens" }]}
      >
        <Input
          type="text"
          min={1}
          placeholder="Please input the Max new tokens"
        />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="presence_penalty"
        label="Presence Penalty"
        rules={[
          { required: true, message: "Please input the presence penalty" },
        ]}
      >
        <Input
          type="text"
          min={1}
          placeholder="Please input the presence penalty"
        />
      </Form.Item>
      <Form.Item {...formItemLayout} name="ai_engine" label="AI Engine">
        <select
          name="ai_engine"
          id="ai_engine"
          style={{ width: "100%" }}
          onChange={(e) => {
            setAiEngine(e.target.value);
          }}
        >
          <option value="mistral">Mistral</option>
          <option value="llama">Llama 3</option>
        </select>
      </Form.Item>
      <Form.Item {...formItemLayout} name="is_lora_model" label="Is Lora Model">
        <select
          name="is_lora_model"
          id="is_lora_model"
          style={{ width: "100%" }}
          disabled={aiEngine !== "llama"}
        >
          {aiEngine === "llama" && <option value="1">True</option>}
          <option value="0">False</option>
        </select>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="stopping_strings"
        label="Stopping Strings"
      >
        <Input
          type="text"
          min={1}
          placeholder="Please input stopping strings"
        />
      </Form.Item>
      <Form.Item {...formItemLayout} name="use_openAI" label="Use OpenAI Format">
        <select
          name="use_openAI"
          id="use_openAI"
          style={{ width: "100%" }}
          disabled={aiEngine !== "llama"}
        >
          {aiEngine === "llama" && <option value="1">True</option>}
          <option value="0">False</option>
        </select>
      </Form.Item>
      <Form.Item {...formItemLayout} name="system_prompt" label="System Prompt">
        <TextArea rows={10} />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="character_context_vllm"
        label="Character Context"
      >
        <TextArea rows={10} />
      </Form.Item>
      {/* <Form.Item
        {...formItemLayout}
        name="character_context_openAI"
        label="Character Context OpenAI"
      >
        <TextArea rows={15} />
      </Form.Item> */}
      <Form.Item
        {...formItemLayout}
        name="usr_model_role"
        label="Users Role (Before the :)"
      >
        <Input type="text" min={1} placeholder="Please input User model role" />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="bot_model_role"
        label="Bots Role (Before the :)"
      >
        <Input type="text" min={1} placeholder="Please input bot model role" />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="usr_in_text_model_role"
        label="User Name in Conversation Text"
      >
        <Input
          type="text"
          min={1}
          placeholder="Please input User in text model role"
        />
      </Form.Item>
      <Form.Item
       {...formItemLayout}
       name="bot_in_text_model_role"
       label="Bot In in Conversation Text"
       style={{ display: 'none' }}
      >
       <Input
         type="text"
         min={1}
         placeholder="Please input bot in text model role"
       />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="regeneration_mode_enabled"
        label="Regeneration Mode Enabled"
      >
        <select
          name="regeneration_mode_enabled"
          id="regeneration_mode_enabled"
          style={{ width: "100%" }}
        >
          <option value="1">True</option>
          <option value="0">False</option>
        </select>
      </Form.Item>
    </>
  );
};

export default ModelSettings;
