import { useState, useEffect } from "react";
import customAxios from "../../utils/axios";
import { jwtDecode } from "jwt-decode";
import { Spin } from "antd";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const TokenPackages = () => {
  const [tokenPackages, setTokenPackages] = useState([]);
  const [editedData, setEditedData] = useState({});
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const token = window.localStorage.getItem("accessToken");
    if (token) {
      const decoded = jwtDecode(token);
      setCurrentUser(decoded.sub);
    }
  }, []);

  useEffect(() => {
    if (currentUser) {
        fetchTokenPackages();
    }
  }, [currentUser]);

  const fetchTokenPackages = async () => {
    setLoading(true);
    try {
      const { data } = await customAxios.get(
        `${SERVER_URL}/token-packages/${currentUser}`
      );
      setTokenPackages(data);
    } catch (error) {
      console.error("Error fetching token packages:", error);
    } finally {
        setLoading(false);
    }
  };

  const handleChange = (id, field, value) => {
    setEditedData((prev) => ({
      ...prev,
      [id]: { ...prev[id], [field]: value },
    }));
  };

  const handleUpdate = async () => {
    setLoading(true);

    const updatedPackages = Object.entries(editedData)
      .map(([id, updatedItem]) => {
        const originalItem = tokenPackages.find((pkg) => pkg.id === Number(id));

        if (originalItem) {
          return {
            id: Number(id),
            package_id: updatedItem.package_id
              ? updatedItem.package_id
              : originalItem.package_id,
            upsell_id: updatedItem.upsell_id
              ? updatedItem.upsell_id
              : originalItem.upsell_id,
            tokens_amount: updatedItem.tokens_amount
              ? Number(updatedItem.tokens_amount)
              : originalItem.tokens_amount,
            price: updatedItem.price
              ? Number(updatedItem.price)
              : originalItem.price,
          };
        }

        return null;
      })
      .filter((item) => item !== null);

    if (updatedPackages.length === 0) {
      setLoading(false);
      return;
    }

    try {
      await customAxios.put(`${SERVER_URL}/token-packages/${currentUser}`, {
        ...updatedPackages,
      });
      fetchTokenPackages();
    } catch (error) {
      console.error("Error updating token packages:", error);
    } finally {
      setLoading(false);
    }
  };

  const groupedPackages = tokenPackages.reduce((acc, pkg) => {
    if (!acc[pkg.type]) acc[pkg.type] = [];
    acc[pkg.type].push(pkg);
    return acc;
  }, {});

  return (
    <div style={{ padding: "1rem" }}>
      {Object.entries(groupedPackages).map(([type, packages]) => (
        <div key={type} style={{ marginBottom: "1.5rem" }}>
          <h2
            style={{
              fontSize: "1.25rem",
              fontWeight: "600",
              marginBottom: "0.5rem",
              textTransform: "capitalize",
            }}
          >
            {type}
          </h2>
          <div
            style={{ display: "grid", gridTemplateColumns: "1fr", gap: "1rem" }}
          >
            {packages.map(({ id, package_id, upsell_id, price, tokens_amount }) => (
              <div
                key={id}
                style={{
                  border: "1px solid #ddd",
                  padding: "1rem",
                  borderRadius: "0.5rem",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                }}
              >
                <div style={{ marginBottom: "1rem" }}>
                  <label
                    style={{
                      display: "block",
                      fontSize: "0.875rem",
                      fontWeight: "500",
                    }}
                  >
                    Option ID:
                  </label>
                  <input
                    type="text"
                    value={editedData[id]?.package_id ?? package_id}
                    onChange={(e) =>
                      handleChange(id, "package_id", e.target.value)
                    }
                    style={{
                      width: "100%",
                      padding: "0.5rem",
                      border: "1px solid #ccc",
                      borderRadius: "0.25rem",
                    }}
                  />
                </div>
                <div style={{ marginBottom: "1rem" }}>
                  <label
                    style={{
                      display: "block",
                      fontSize: "0.875rem",
                      fontWeight: "500",
                    }}
                  >
                    Upsell ID:
                  </label>
                  <input
                    type="text"
                    value={editedData[id]?.upsell_id ?? upsell_id}
                    onChange={(e) =>
                      handleChange(id, "upsell_id", e.target.value)
                    }
                    style={{
                      width: "100%",
                      padding: "0.5rem",
                      border: "1px solid #ccc",
                      borderRadius: "0.25rem",
                    }}
                  />
                </div>
                <div style={{ marginBottom: "1rem" }}>
                  <label
                    style={{
                      display: "block",
                      fontSize: "0.875rem",
                      fontWeight: "500",
                    }}
                  >
                    Price ($):
                  </label>
                  <input
                    type="number"
                    step="0.01"
                    value={editedData[id]?.price ?? price}
                    onChange={(e) => handleChange(id, "price", e.target.value)}
                    style={{
                      width: "100%",
                      padding: "0.5rem",
                      border: "1px solid #ccc",
                      borderRadius: "0.25rem",
                    }}
                  />
                </div>
                <div style={{ marginBottom: "1rem" }}>
                  <label
                    style={{
                      display: "block",
                      fontSize: "0.875rem",
                      fontWeight: "500",
                    }}
                  >
                    Tokens Value ($):
                  </label>
                  <input
                    type="number"
                    value={editedData[id]?.tokens_amount ?? tokens_amount}
                    onChange={(e) =>
                      handleChange(id, "tokens_amount", e.target.value)
                    }
                    style={{
                      width: "100%",
                      padding: "0.5rem",
                      border: "1px solid #ccc",
                      borderRadius: "0.25rem",
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
      <Spin spinning={loading}>
        <button
          onClick={handleUpdate}
          style={{
            backgroundColor: "#3b82f6",
            color: "white",
            padding: "0.5rem 1rem",
            borderRadius: "0.25rem",
            marginTop: "1rem",
          }}
        >
          Update Packages
        </button>
      </Spin>
    </div>
  );
};

export default TokenPackages;