import React, {useState, useMemo} from "react";
import {Modal, Select, Input, Button, notification} from "antd";
import axios from "../../utils/axios";
import {SERVER_URL} from "../../Constants";


const SELECT_OPTIONS = [{
    value: "all", label: 'All'
}, {
    value: "metart", label: 'Metart'
}, {
    value: "carnal", label: 'Carnal'
}, {
    value: "sirenai", label: 'Sirenai'
},]

const Context = React.createContext({
    name: 'Default',
});

const UpdateEndpointModal = ({isOpen, onClose}) => {
    const [api, contextHolder] = notification.useNotification();


    const [isLoading, setIsLoading] = useState(false)
    const [formData, setFormData] = useState({selectValue: "all", inputValue: ""});

    const openNotification = (placement) => {
        api.error({
            message: `Error`,
            description: <Context.Consumer>{() => `Something went wrong!`}</Context.Consumer>,
            placement,
        });
    };

    const contextValue = useMemo(() => ({
        name: 'Ant Design',
    }), [],);

    const handleChange = (field, value) => {
        setFormData(prev => ({...prev, [field]: value}));
    };

    const handleSubmit = async () => {
        setIsLoading(true)
        try {
            console.log("Form Data Submitted:", formData);
            await axios.put(SERVER_URL + "/influencer/endpoint", formData);
            onClose()
        } catch (error) {
            console.error(error)
            openNotification('topRight')
        } finally {
            setIsLoading(false)
        }

    };

    return <Context.Provider value={contextValue}>{contextHolder}<Modal
        title={"Publish on main page"}
        open={isOpen}
        onCancel={onClose}
        footer={null}
    >
        <div style={{marginTop: '16px'}}>
            <label>Select an option:</label>
            <Select
                style={{width: "100%", marginBottom: 8}}
                defaultValue={SELECT_OPTIONS[0].value}
                placeholder="Select an option"
                onChange={(value) => handleChange("selectValue", value)}
            >
                {SELECT_OPTIONS.map((option) => {
                    return <Select.Option key={`update-endpoint-option-${option.value}`}
                                          value={option.value}>{option.label}</Select.Option>
                })}

            </Select>

            <label>Enter a value:</label>
            <Input
                placeholder="Enter a value"
                onChange={(e) => handleChange("inputValue", e.target.value)}
            />
        </div>

        <Button type="primary" onClick={handleSubmit} style={{marginTop: 16}}>
            Submit
        </Button>
    </Modal>
    </Context.Provider>
}


export default UpdateEndpointModal
