import { Table, Button, Modal, Input, Form, Select, Tag, message } from "antd";
import React, { useState, useEffect, useMemo } from "react";
import { jwtDecode } from "jwt-decode";
import customAxios from "../../utils/axios";
import axios from "axios";
import { countries } from "../../Constants";

import DownloadCSV from "../../Components/DownloadCSV";

import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

import BarLoader from "react-spinners/BarLoader";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const { TextArea } = Input;

const fieldDefinitions = [
  { name: "start_message", label: "Start Message", type: "textarea" },
  { name: "creditsAdded_Msg", label: "Promo Credits Added", type: "input" },
  { name: "influencer_select_pre", label: "Influencer Select Pre Message", type: "input" },
  { name: "influencer_select_success", label: "Influencer selected successfully", type: "input" },
  { name: "select_influencer", label: "Influencer needs to be selected", type: "input" },
  { name: "history_cleared", label: "History Cleared successfully", type: "input" },
  { name: "change_name_question", label: "Change Name Question", type: "input" },
  { name: "change_name_success", label: "Successfully changed name", type: "textarea" },
  { name: "feedback_question", label: "Feedback Question", type: "input" },
  { name: "feedback_success", label: "Successfully submitted feedback", type: "input" },
  { name: "feedback_ask", label: "Feedback Question", type: "input" },
  { name: "stripe_payments", label: "Payment Info", type: "input" },
  { name: "deposit_pricing", label: "Deposit Pricing", type: "textarea" },
  { name: "deposit_cancel", label: "Deposit / Tip Canceled", type: "input" },
  { name: "tip_info", label: "Tip Info", type: "textarea" },
  { name: "specialrequest_pre", label: "Specialrequest Pre Message", type: "input" },
  { name: "specialrequest_contact", label: "Specialrequest Contact Message", type: "input" },
  { name: "specialrequest_success", label: "Specialrequest Success Message", type: "input" },
  { name: "change_voice_success", label: "Change Voice Success", type: "input" },
  { name: "voice_disabled_global", label: "Voice Messages Disabled Globally", type: "input" },
  { name: "no_voice_for_influencer", label: "Voice Messages Disabled for Influencer", type: "input" },
  { name: "set_bio", label: "Ask User to set Bio", type: "textarea" },
  { name: "set_bio_success", label: "Successfully set Bio", type: "input" },
  { name: "canceled_message", label: "Canceled Message", type: "input" },
  { name: "menu_msg", label: "Menu Message", type: "textarea" },
  { name: "nudity_hint_msg", label: "Nudity Hint Message", type: "input" },
  { name: "supportMsg", label: "Support Message", type: "input" },
  { name: "bot_offline", label: "Bot Offline", type: "input" },
  { name: "country_blocked", label: "Country Blocked", type: "input" },
  { name: "user_banned", label: "User Banned", type: "input" },
  { name: "no_balance", label: "No Balance", type: "textarea" },
  { name: "username_needed", label: "Username needed", type: "input" },
  { name: "contact_admin", label: "Contact Admin", type: "input" },
  { name: "no_influencer", label: "No Influencer Selected", type: "input" },
  { name: "against_terms", label: "Against Terms of Service", type: "textarea" },
  { name: "termsofuse", label: "Terms of Use", type: "textarea" },
  { name: "privacyPolicy", label: "Privacy Policy", type: "textarea" },
  { name: "examples_message", label: "Examples Message", type: "textarea" },
  { name: "need_to_start_message", label: "Ask user to start first message", type: "input" }
];

const SystemMessages = () => {
  const [loading, setLoading] = useState(true);

  const [form] = Form.useForm();
  const [data, setData] = useState([
    {
      bot_offline: "",
      country_blocked: "",
      user_banned: "",
      no_balance: "",
      start_message: "",
      username_needed: "",
      contact_admin: "",
      no_influencer: "",
      history_cleared: "",
      change_name_question: "",
      feedback_question: "",
      select_influencer: "",
      change_name_success: "",
      feedback_success: "",
      feedback_ask: "",
      influencer_select_success: "",
      stripe_payments: "",
      change_voice_success: "",
      deposit_cancel: "",
      deposit_pricing: "",
      voice_disabled_global: "",
      no_voice_for_influencer: "",
      against_terms : "",
      set_bio: "",
      set_bio_success: "",
      influencer_select_pre: "",
      specialrequest_pre: "",
      specialrequest_contact: "",
      specialrequest_success: "",
      tip_info: "",
      termsofuse: "",
      privacyPolicy: "",
      examples_message: "",
      need_to_start_message: "",
      canceled_message: "",
      menu_msg: "",
      nudity_hint_msg: "",
      supportMsg: "",
      creditsAdded_Msg: "",
    }
  ]);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAddMsgModalOpen, setIsAddMsgModalOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const sendMessageOptions = useMemo(
    () => data.map((item) => ({ value: item.chat_id, label: item.username })).filter((item) => item.value !== null),
    [data]
  );
  const [messageApi, contextHolder] = message.useMessage();

  const formItemLayout = null;

  useEffect(() => {
      const token = window.localStorage.getItem("accessToken");
  
      if (token) {
        const decoded = jwtDecode(token);
        setCurrentUser(decoded.sub);
      }
    }, []);

  const columns = [
    {
      title: "/start Messages",
      type: "Title",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Start Message",
      type: "Item",
      dataIndex: "start_message",
      key: "start_message",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Promo Credits Added",
      type: "Item",
      dataIndex: "creditsAdded_Msg",
      key: "creditsAdded_Msg",
      render: (text) => <div>{text}</div>
    },
    {
      title: "/name Messages",
      type: "Title",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Influencer Select Pre Message",
      type: "Item",
      dataIndex: "influencer_select_pre",
      key: "influencer_select_pre",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Influencer selected successfully",
      type: "Item",
      dataIndex: "influencer_select_success",
      key: "influencer_select_success",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Influencer needs to be selected",
      type: "Item",
      dataIndex: "select_influencer",
      key: "select_influencer",
      render: (text) => <div>{text}</div>
    },
    {
      title: "/clear Messages",
      type: "Title",
      render: (text) => <div>{text}</div>
    },
    {
      title: "History Cleared successfully",
      type: "Item",
      dataIndex: "history_cleared",
      key: "history_cleared",
      render: (text) => <div>{text}</div>
    },
    {
      title: "/changeName Messages",
      type: "Title",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Change Name Question",
      type: "Item",
      dataIndex: "change_name_question",
      key: "change_name_question",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Successfully changed name",
      type: "Item",
      dataIndex: "change_name_success",
      key: "change_name_success",
      render: (text) => <div>{text}</div>
    },
    {
      title: "/feedback Messages",
      type: "Title",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Feedback Question",
      type: "Item",
      dataIndex: "feedback_question",
      key: "feedback_question",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Successfully submitted feedback",
      type: "Item",
      dataIndex: "feedback_success",
      key: "feedback_success",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Feedback Question",
      type: "Item",
      dataIndex: "feedback_ask",
      key: "feedback_ask",
      render: (text) => <div>{text}</div>
    },
    {
      title: "/deposit Messages",
      type: "Title",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Payment Info",
      type: "Item",
      dataIndex: "stripe_payments",
      key: "stripe_payments",
      render: (text) => <div>{text}</div>
    },
    {
      title: "/deposit Messages",
      type: "Title",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Deposit Pricing",
      type: "Item",
      dataIndex: "deposit_pricing",
      key: "deposit_pricing",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Deposit / Tip Canceled",
      type: "Item",
      dataIndex: "deposit_cancel",
      key: "deposit_cancel",
      render: (text) => <div>{text}</div>
    },
    {
      title: "/tip Messages",
      type: "Title",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Tip Info",
      type: "Item",
      dataIndex: "tip_info",
      key: "tip_info",
      render: (text) => <div>{text}</div>
    },
    {
      title: "/specialRequest Messages",
      type: "Title",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Specialrequest Pre Message",
      type: "Item",
      dataIndex: "specialrequest_pre",
      key: "specialrequest_pre",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Specialrequest Contact Message",
      type: "Item",
      dataIndex: "specialrequest_contact",
      key: "specialrequest_contact",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Specialrequest Success Message",
      type: "Item",
      dataIndex: "specialrequest_success",
      key: "specialrequest_success",
      render: (text) => <div>{text}</div>
    },
    {
      title: "/enableVoice - /disableVoice Messages",
      type: "Title",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Change Voice Success",
      type: "Item",
      dataIndex: "change_voice_success",
      key: "change_voice_success",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Voice Messages Disabled Globally",
      type: "Item",
      dataIndex: "voice_disabled_global",
      key: "voice_disabled_global",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Voice Messages Disabled for Influencer",
      type: "Item",
      dataIndex: "no_voice_for_influencer",
      key: "no_voice_for_influencer",
      render: (text) => <div>{text}</div>
    },
    {
      title: "/bio Messages",
      type: "Title",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Ask User to set Bio",
      type: "Item",
      dataIndex: "set_bio",
      key: "set_bio",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Successfully set Bio",
      type: "Item",
      dataIndex: "set_bio_success",
      key: "set_bio_success",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Bot Offline",
      type: "Item",
      dataIndex: "bot_offline",
      key: "bot_offline",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Country Blocked",
      type: "Item",
      dataIndex: "country_blocked",
      key: "country_blocked",
      render: (text) => <div>{text}</div>
    },
    {
      title: "/cancel Messages",
      type: "Title",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Canceled Message",
      type: "Item",
      dataIndex: "canceled_message",
      key: "canceled_message",
      render: (text) => <div>{text}</div>
    },
    {
      title: "/menu Messages",
      type: "Title",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Menu Message",
      type: "Item",
      dataIndex: "menu_msg",
      key: "menu_msg",
      render: (text) => <div>{text}</div>
    },
    {
      title: "/customPic Messages",
      type: "Title",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Nudity Hint Message",
      type: "Item",
      dataIndex: "nudity_hint_msg",
      key: "nudity_hint_msg",
      render: (text) => <div>{text}</div>
    },
    {
      title: "/support Messages",
      type: "Title",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Support Message",
      type: "Item",
      dataIndex: "supportMsg",
      key: "supportMsg",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Other Messages",
      type: "Title",
      render: (text) => <div>{text}</div>
    },
    {
      title: "User Banned",
      type: "Item",
      dataIndex: "user_banned",
      key: "user_banned",
      render: (text) => <div>{text}</div>
    },
    {
      title: "No Balance",
      type: "Item",
      dataIndex: "no_balance",
      key: "no_balance",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Username needed",
      type: "Item",
      dataIndex: "username_needed",
      key: "username_needed",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Contact Admin",
      type: "Item",
      dataIndex: "contact_admin",
      key: "contact_admin",
      render: (text) => <div>{text}</div>
    },
    {
      title: "No Influencer Selected",
      type: "Item",
      dataIndex: "no_influencer",
      key: "no_influencer",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Against Terms of Service",
      type: "Item",
      dataIndex: "against_terms",
      key: "against_terms",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Termsofuse",
      type: "Item",
      dataIndex: "termsofuse",
      key: "termsofuse",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Privacy Policy",
      type: "Item",
      dataIndex: "privacyPolicy",
      key: "privacyPolicy",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Examples Message",
      type: "Item",
      dataIndex: "examples_message",
      key: "examples_message",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Ask user to start first message",
      type: "Item",
      dataIndex: "need_to_start_message",
      key: "need_to_start_message",
      render: (text) => <div>{text}</div>
    }


    // {
    //   title: "Action",
    //   key: "action",
    //   width: "10%",
    //   render: (text, record) => (
    //     <div style={{ display: "flex", gap: "0.5rem" }}>
    //       <Button
    //         type="primary"
    //         danger
    //         onClick={() => {
    //           editDetail(record);
    //         }}
    //       >
    //         <EditOutlined />
    //       </Button>
    //     </div>
    //   )
    // }
  ];

  function editDetail(record) {
    form.setFieldsValue({
      bot_offline: record.bot_offline,
      country_blocked: record.country_blocked,
      user_banned: record.user_banned,
      no_balance: record.no_balance,
      start_message: record.start_message,
      username_needed: record.username_needed,
      contact_admin: record.contact_admin,
      no_influencer: record.no_influencer,
      history_cleared: record.history_cleared,
      change_name_question: record.change_name_question,
      feedback_question: record.feedback_question,
      select_influencer: record.select_influencer,
      change_name_success: record.change_name_success,
      feedback_success: record.feedback_success,
      feedback_ask: record.feedback_ask,
      influencer_select_success: record.influencer_select_success,
      stripe_payments: record.stripe_payments,
      change_voice_success: record.change_voice_success,
      deposit_cancel: record.deposit_cancel,
      deposit_pricing: record.deposit_pricing,
      voice_disabled_global: record.voice_disabled_global,
      no_voice_for_influencer: record.no_voice_for_influencer,
      against_terms: record.against_terms,
      set_bio: record.set_bio,
      set_bio_success: record.set_bio_success,
      influencer_select_pre: record.influencer_select_pre,
      specialrequest_pre: record.specialrequest_pre,
      specialrequest_contact: record.specialrequest_contact,
      specialrequest_success: record.specialrequest_success,
      tip_info: record.tip_info,
      termsofuse: record.termsofuse,
      privacyPolicy: record.privacyPolicy,
      examples_message: record.examples_message,
      need_to_start_message: record.need_to_start_message,
      canceled_message: record.canceled_message,
      menu_msg: record.menu_msg,
      nudity_hint_msg: record.nudity_hint_msg,
      supportMsg: record.supportMsg,
      creditsAdded_Msg: record.creditsAdded_Msg,
    });
    showEditMsgModal();
  }

  useEffect(() => {
    if (currentUser) {
      getMsgData();
    }
  }, [currentUser]);

  const getMsgData = () => {
    customAxios.get(SERVER_URL + `/system/messages/${currentUser}`).then(function (res) {
      setData(res.data);
      setLoading(false);
    });
  };

  const showEditMsgModal = () => {
    setIsEditModalOpen(true);
  };


  const handleMsgOk = async () => {
    try {
    const messages = form.getFieldsValue();
    const { data } = await customAxios.put(SERVER_URL + `/system/messages/${currentUser}`, { ...messages });
    setData(data);
    setIsEditModalOpen(false);
    } catch (error) {
      console.log("--ERROR--", error);
    } finally {
      clearFormData();
    }
  };
  const handleUserCancel = () => {
    setIsEditModalOpen(false);
    clearFormData();
  };

  function clearFormData() {
    form.setFieldsValue({
      username: "",
      age: null,
      location: "",
    });
  }

  const handleMsgCancel = () => {
    setIsAddMsgModalOpen(false);
  };

  return (
    <div>
      {contextHolder}
      <div>
      </div>
      {/* <div>
        <Table style={{ marginTop: "8px" }} columns={columns} dataSource={data} rowKey="id" />
      </div> */}



        {loading &&
          <BarLoader color="#1677ff" 
            cssOverride={{
              display: "block",
              margin: "10vh auto",
              borderColor: "red",
            }}
            size={150}
          />
        }
        {!loading &&
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {columns
              .filter((column) => Object.keys(data[0]).includes(column.key))
              .map((column) => {
              return (
                <>
                {column.type === "Title" && <h2>{column.title}</h2>}
                {column.type === "subTitle" && <h3>{column.title}</h3>}
                {column.type === "Item" && 
                  <>
                    <div key={column.key} style={{ margin: '10px', padding: '10px', border: '1px solid gray' }}>
                      <h3>{column.title}</h3>
                      <ul style={{ listStyleType: 'none', padding: 0 }}>
                        {data.map((item) => (
                          <li key={item.key}>{column.render(item[column.dataIndex])}</li>
                        ))}
                      </ul>
                    </div>
                  </>
                }
                

              </>
            )})}
            <div style={{ margin: '10px', padding: '10px' }}>
              <ul style={{ listStyleType: 'none', padding: 0 }}>
                {data.map((item) => (
                  <li key={item.key} style={{ margin: '5px' }}>
                    <Button
                      type="primary"
                      danger
                      onClick={() => {
                        editDetail(item);
                      }}
                    >
                      <EditOutlined />
                    </Button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        }




      <Modal
        title={"Edit user" }
        open={isEditModalOpen}
        onOk={handleMsgOk}
        onCancel={handleUserCancel}
      >
        {/* <Form form={form} name="dynamic_rule" layout="vertical">
          
          <h2>/start Messages</h2>
          <Form.Item
            {...formItemLayout}
            name="start_message"
            label="Start Message"
            rules={[{ required: true, message: "Please input start message" }]}
          >
            <TextArea rows={4} placeholder="Please input start message" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="creditsAdded_Msg"
            label="Promo Credits Added"
            rules={[{ required: true, message: "Please input promo credits added" }]}
          >
            <Input placeholder="Please input promo credits added" />
          </Form.Item>
          <h2>/name Messages</h2>
          <Form.Item
            {...formItemLayout}
            name="influencer_select_pre"
            label="Influencer Select Pre Message"
            rules={[{ required: true, message: "Please input influencer select pre message" }]}
          >
            <Input placeholder="Please input influencer select pre message" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="influencer_select_success"
            label="Influencer selected successfully"
            rules={[{ required: true, message: "Please input influencer selected successfully" }]}
          >
            <Input placeholder="Please input influencer selected successfully" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="select_influencer"
            label="Influencer needs to be selected" 
            rules={[{ required: true, message: "Please input influencer needs to be selected" }]}
          >
            <Input placeholder="Please input influencer needs to be selected" />
          </Form.Item>

          
          <h2>/clear Messages</h2>
          <Form.Item
            {...formItemLayout}
            name="history_cleared"
            label="History Cleared successfully"
            rules={[{ required: true, message: "Please input history cleared successfully" }]}
          >
            <Input placeholder="Please input history cleared successfully" />
          </Form.Item>

          
          <h2>/changeName Messages</h2>
          <Form.Item
            {...formItemLayout}
            name="change_name_question"
            label="Change Name Question"
            rules={[{ required: true, message: "Please input change name question" }]}
          >
            <Input placeholder="Please input change name question" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="change_name_success"
            label="Successfully changed name"
            rules={[{ required: true, message: "Please input successfully changed name" }]}
          >
            <TextArea rows={4} placeholder="Please input successfully changed name" />
          </Form.Item>

          
          <h2>/feedback Messages</h2>
          <Form.Item
            {...formItemLayout}
            name="feedback_question"
            label="Feedback Question"
            rules={[{ required: true, message: "Please input feedback question" }]}
          >
            <Input placeholder="Please input feedback question" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="feedback_success"
            label="Successfully submitted feedback"
            rules={[{ required: true, message: "Please input successfully submitted feedback" }]}
          >
            <Input placeholder="Please input successfully submitted feedback" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="feedback_ask"
            label="Feedback Question"
            rules={[{ required: true, message: "Please input feedback question" }]}
          >
            <Input placeholder="Please input feedback question" />
          </Form.Item>

          
          <h2>/deposit Messages</h2>
          <Form.Item
            {...formItemLayout}
            name="stripe_payments"
            label="Payment Info"
            rules={[{ required: true, message: "Please input payment info" }]}
          >
            <Input placeholder="Please input payment info" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="deposit_pricing"
            label="Deposit Pricing"
            rules={[{ required: true, message: "Please input deposit pricing" }]}
          >
            <TextArea rows={4} placeholder="Please input deposit pricing" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="deposit_cancel"
            label="Deposit / Tip Canceled"
            rules={[{ required: true, message: "Please input deposit / tip canceled" }]}
          >
            <Input placeholder="Please input deposit / tip canceled" />
          </Form.Item>

          
          <h2>/tip Messages</h2>
          <Form.Item
            {...formItemLayout}
            name="tip_info"
            label="Tip Info"
            rules={[{ required: true, message: "Please input tip info" }]}
          >
            <TextArea rows={4} placeholder="Please input tip info" />
          </Form.Item>

          
          <h2>/specialRequest Messages</h2>
          <Form.Item
            {...formItemLayout}
            name="specialrequest_pre"
            label="Specialrequest Pre Message"
            rules={[{ required: true, message: "Please input specialrequest pre message" }]}
          >
            <Input placeholder="Please input specialrequest pre message" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="specialrequest_contact"
            label="Specialrequest Contact Message"
            rules={[{ required: true, message: "Please input specialrequest contact message" }]}
          >
            <Input placeholder="Please input specialrequest contact message" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="specialrequest_success"
            label="Specialrequest Success Message"
            rules={[{ required: true, message: "Please input specialrequest success message" }]}
          >
            <Input placeholder="Please input specialrequest success message" />
          </Form.Item>

          
          <h2>/enableVoice - /disableVoice Messages</h2>
          <Form.Item
            {...formItemLayout}
            name="change_voice_success"
            label="Change Voice Success"
            rules={[{ required: true, message: "Please input change voice success" }]}
          >
            <Input placeholder="Please input change voice success" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="voice_disabled_global"
            label="Voice Messages Disabled Globally"
            rules={[{ required: true, message: "Please input voice messages disabled globally" }]}
          >
            <Input placeholder="Please input voice messages disabled globally" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="no_voice_for_influencer"
            label="Voice Messages Disabled for Influencer"
            rules={[{ required: true, message: "Please input voice messages disabled for influencer" }]}
          >
            <Input placeholder="Please input voice messages disabled for influencer" />
          </Form.Item>

          
          <h2>/bio Messages</h2>
          <Form.Item
            {...formItemLayout}
            name="set_bio"
            label="Ask User to set Bio"
            rules={[{ required: true, message: "Please input ask user to set bio" }]}
          >
            <TextArea rows={4} placeholder="Please input ask user to set bio" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="set_bio_success"
            label="Successfully set Bio"
            rules={[{ required: true, message: "Please input successfully set bio" }]}
          >
            <Input placeholder="Please input successfully set bio" />
          </Form.Item>
          <h2>/cancel Messages</h2>
          <Form.Item
            {...formItemLayout}
            name="canceled_message"
            label="Canceled Message"
            rules={[{ required: true, message: "Please input canceled message" }]}
          >
            <Input placeholder="Please input canceled message" />
          </Form.Item>
          <h2>/menu Messages</h2>
          <Form.Item
            {...formItemLayout}
            name="menu_msg"
            label="Menu Message"
            rules={[{ required: true, message: "Please input menu message" }]}
          >
            <TextArea rows={10} placeholder="Please input menu message" />
          </Form.Item>
          <h2>/customPic Messages</h2>
          <Form.Item
            {...formItemLayout}
            name="nudity_hint_msg"
            label="Nudity Hint Message"
            rules={[{ required: true, message: "Please input nudity hint message" }]}
          >
            <Input placeholder="Please input nudity hint message" />
          </Form.Item>
          <h2>/support Messages</h2>
          <Form.Item
            {...formItemLayout}
            name="supportMsg"
            label="Support Message"
            rules={[{ required: true, message: "Please input support message" }]}
          >
            <Input placeholder="Please input support message" />
          </Form.Item>
          <h2>Other Messages</h2>
          <Form.Item
            {...formItemLayout}
            name="bot_offline"
            label="Bot Offline"
            rules={[{ required: true, message: "Please input bot offline" }]}
          >
            <Input placeholder="Please input bot offline" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="country_blocked"
            label="Country Blocked"
            rules={[{ required: true, message: "Please input country blocked" }]}
          >
            <Input placeholder="Please input country blocked" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="user_banned"
            label="User Banned"
            rules={[{ required: true, message: "Please input user banned" }]}
          >
            <Input placeholder="Please input user banned" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="no_balance"
            label="No Balance"
            rules={[{ required: true, message: "Please input no balance" }]}
          >
            <TextArea rows={4} placeholder="Please input no balance" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="username_needed"
            label="Username needed"
            rules={[{ required: true, message: "Please input username needed" }]}
          >
            <Input placeholder="Please input username needed" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="contact_admin"
            label="Contact Admin"
            rules={[{ required: true, message: "Please input contact admin" }]}
          >
            <Input placeholder="Please input contact admin" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="no_influencer"
            label="No Influencer Selected"
            rules={[{ required: true, message: "Please input no influencer selected" }]}
          >
            <Input placeholder="Please input no influencer selected" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="against_terms"
            label="Against Terms of Service"
            rules={[{ required: true, message: "Please input against terms of service" }]}
          >
            <TextArea rows={4} placeholder="Please input against terms of service" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="termsofuse"
            label="Terms of Use"
            rules={[{ required: true, message: "Please input termsofuse" }]}
          >
            <TextArea rows={4} placeholder="Please input the termsofuse" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="privacyPolicy"
            label="Privacy Policy"
            rules={[{ required: true, message: "Please input privacy policy" }]}
          >
            <TextArea rows={4} placeholder="Please input a privacy policy" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="examples_message"
            label="Examples Message"
            rules={[{ required: true, message: "Please input examples message" }]}
          >
            <TextArea rows={4} placeholder="Please input a examples message" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="need_to_start_message"
            label="Ask user to start first message"
            rules={[{ required: true, message: "Please input ask user to start first message" }]}
          >
            <Input placeholder="Please input ask user to start first message" />
          </Form.Item>

        </Form> */}

        <Form form={form} name="dynamic_rule" layout="vertical">
            {fieldDefinitions
              .filter(field => data[0][field.name] !== undefined) // Render only existing fields
              .map(field => (
                <Form.Item
                  key={field.name}
                  name={field.name}
                  label={field.label}
                  rules={[{ required: false }]} // Fields are optional
                >
                  {field.type === "textarea" ? (
                    <TextArea rows={4} placeholder={`Please input ${field.label}`} />
                  ) : (
                    <Input placeholder={`Please input ${field.label}`} />
                  )}
                </Form.Item>
              ))}
          </Form>
      </Modal>
    </div>
  );
};

export default SystemMessages;