import {
  Table,
  Button,
  Modal,
  Input,
  Form,
  Select,
  Tag,
  message,
  Space,
  Popconfirm,
  Spin,
} from "antd";
import dayjs from "dayjs";
import React, { useState, useEffect, useMemo, useRef } from "react";
import customAxios from "../../utils/axios";
import { countries, countriesRaw } from "../../Constants";
import { useTimezoneSelect, allTimezones } from "react-timezone-select";
import debounce from "lodash/debounce";
import {
  EditOutlined,
  DeleteOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";

import BarLoader from "react-spinners/BarLoader";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const { TextArea } = Input;

const formItemLayout = null;

function DebounceSelect({
  fetchOptions,
  debounceTimeout = 800,
  label,
  ...props
}) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);
  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }
        setOptions(newOptions);
        setFetching(false);
      });
    };
    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);
  return (
    <Form.Item
      {...formItemLayout}
      name="q"
      label={label}
      style={{
        width: "100%",
      }}
      rules={[{ required: true, message: "Please select user id" }]}
    >
      <Select
        labelInValue
        showSearch={true}
        filterOption={false}
        onSearch={debounceFetcher}
        notFoundContent={fetching ? <Spin size="small" /> : null}
        {...props}
        options={options}
      />
    </Form.Item>
  );
}

const User = () => {
  const [loading, setLoading] = useState(true);

  const [form] = Form.useForm();
  const [balance, setBalance] = useState(0);
  const [data, setData] = useState([
    {
      user_id: "",
      email: "",
      age: "",
      location: "",
      balance: "",
      last_login: "",
      whitelisted: "",
      created_at: "",
    },
  ]);
  const [totalData, setTotalData] = useState([]);
  const [totalUser, setTotalUser] = useState();
  const [userOption, setUserOption] = useState([]);
  const [fileInput, setFileInput] = useState(null);
  const [fileUploadPath, setFileUploadPath] = useState("");
  const [fileUploadLoading, setFileUploadLoading] = useState(false);
  const [tokenOperationList, setTokenOperationList] = useState([]);

  const [sendingMessage, setSendingMessage] = useState(false);

  const [isBalanceOperationModal, setIsBalanceOperationModal] = useState(false);
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const [isAddMsgModalOpen, setIsAddMsgModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState("");
  const [settings, setSettings] = useState([
    {
      notification_frequency: "48",
      blocked_countries: "allow_all",
    },
  ]);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);

  const [roleOptions, setRoleOptions] = useState([]);
  const [totalInfluencer, setTotalInfluencer] = useState([]);

  const [timezoneOptions, setTimezoneOptions] = useState([]);

  const labelStyle = "original";
  const timezones = {
    ...allTimezones,
    "Europe/Berlin": "Frankfurt",
  };
  const { options, parseTimezone } = useTimezoneSelect({
    labelStyle,
    timezones,
  });

  useEffect(() => {
    let tempTimezoneOptions = [];
    for (let i = 0; i < options.length; i++) {
      tempTimezoneOptions.push({
        value: options[i].value,
        label: options[i].label,
      });
    }
    // console.log("tempTimezoneOptions", tempTimezoneOptions);
    setTimezoneOptions(tempTimezoneOptions);
  }, []);

  const [influencerData, setInfluencerData] = useState([
    {
      id: "",
      name: "",
      lora_model_name: "",
      server_ip_1: "",
      server_ip_2: "",
      server_ip_3: "",
      server_ip_4: "",
      character: "",
      voice_id: "",
      voice_id_2: "",
      voice_model_id: "",
      voice_model_id_2: "",
      open_ai_model: "",
      link_search_model: "",
      intent_engine_model: "",
      prohibited_topics: "",
      temperature: "",
      top_p: "",
      top_k: "",
      min_length: "",
      max_new_tokens: "",
      notification_frequency: "",
      notification_message: "",
      tags: "",
      is_on_home: "",
      description: "",
      likes: "",
      comments: "",
      shares: "",
      followers: "",
      languages: "",
      location: "",
      gender: "",
      cover: "",
      image_examples_urls: [],
      image_examples_url: "",
      examples_prompts: "",
      image_generation_only: "",
    },
  ]);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const getInfluencerData = () => {
    customAxios.get(SERVER_URL + "/influencer").then(function (res) {
      setInfluencerData(res.data);
    });
  };

  const [sendInfluencerMessageOptions, setSendInfluencerMessageOptions] =
    useState([]);

  useEffect(() => {
    setSendInfluencerMessageOptions(
      influencerData
        .map((item) => ({ value: item.id, label: item.name }))
        .filter((item) => item.value !== null)
    );
  }, [influencerData]);

  const [sendToAll, setSendToAll] = useState(false);

  useEffect(() => {
    if (sendToAll) {
      form.setFieldsValue({ users: data.map((item) => item.id) });
    } else {
      form.setFieldsValue({ users: [] });
    }
  }, [sendToAll]);

  const [showUserEmails, setShowUserEmails] = useState(false);
  const [sendMessageOptions, setSendMessageOptions] = useState([]);

  useEffect(() => {
    if (showUserEmails) {
      // Show by email
      setSendMessageOptions(
        data
          .map((item) => ({ value: item.id, label: item.email }))
          .filter((item) => item.value !== null)
      );
    } else {
      // Show by user id
      setSendMessageOptions(
        data
          .map((item) => ({ value: item.id, label: item.id }))
          .filter((item) => item.value !== null)
      );
    }
  }, [showUserEmails, data]);

  const [messageApi, contextHolder] = message.useMessage();

  const [locationOptions] = useState(
    countriesRaw.map((item) => ({ value: item.label, label: item.label }))
  );

  const formItemLayout = null;

  const columns = [
    {
      title: "SirenAI ID",
      dataIndex: "id",
      key: "id",
      render: (text) => <div>{text}</div>,
    },
    {
      title: (
        <>
          <div>Nickname:</div>
          <div>Email or PaysiteUUID:</div>
        </>
      ),
      dataIndex: "email",
      key: "email",
      render: (text, values) => (
        <>
          <div style={{ marginBottom: "8px" }}>{values?.username}</div>
          <div>{text}</div>
        </>
      ),
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      render: (text) => <div>{Math.round(Number(text) * 100) / 100}</div>,
    },
    {
      title: "Last login",
      dataIndex: "last_login",
      key: "last_login",
      render: (text) => (
        <div>
          {text ? dayjs(text).format("YYYY-MM-DD HH:mm") : "Not Verified"}
        </div>
      ),
    },
    {
      title: "Sign up date",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => (
        <div>{text ? dayjs(text).format("YYYY-MM-DD HH:mm") : "Never"}</div>
      ),
    },
    {
      title: (
        <>
          <div>Status:</div>
          <div>Whitelisted:</div>
        </>
      ),
      dataIndex: "status",
      key: "status",
      render: (text, values) => (
        <>
          <Tag
            style={{ marginBottom: "8px" }}
            color={
              text === "banned"
                ? "red"
                : text === "Not Started"
                ? "gold"
                : "green"
            }
          >
            {text}
          </Tag>
          <Tag color={values?.whitelisted === "0" ? "red" : "green"}>
            {values?.whitelisted === "0" ? "False" : "True"}
          </Tag>
        </>
      ),
    },
    {
      title: "Role",
      dataIndex: "role_id",
      key: "role_id",
      render: (role_id) => (
        <div>
          {roleOptions.map((item) => {
            if (item.value === role_id) {
              return item.label;
            }
          })}
        </div>
      ),
    },
    {
      title: "PWA Bonus Added",
      dataIndex: "pwa_bonus_count",
      key: "pwa_bonus_count",
      render: (text) => <div>{text || "0"}</div>,
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (_text, record) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {record?.email !== "None" && (
            <Button
              style={{ marginTop: 0, marginBottom: 8 }}
              type="primary"
              danger
              onClick={() => showBalanceOperationModal(record?.user_id)}
            >
              <UnorderedListOutlined />
            </Button>
          )}

          <Button
            style={{ marginTop: 0, marginBottom: 8 }}
            type="primary"
            danger
            onClick={() => {
              editDetail(record);
            }}
            disabled={!record?.status === "Active"}
          >
            <EditOutlined />
          </Button>

          <Button
            style={{ marginTop: 0 }}
            type="primary"
            danger
            onClick={() => OpenDeleteRecordAreYouSure(record.id)}
          >
            <DeleteOutlined />
          </Button>

          <Popconfirm
            open={record.deleteActive}
            title="Are you sure？"
            okText="Delete"
            onConfirm={() => deleteRecord(record.id)}
            onCancel={() => CloseDeleteRecordAreYouSure(record.id)}
            cancelText="No"
          ></Popconfirm>
        </div>
      ),
    },
  ];

  const columnsTotal = [
    {
      title: "Total PWA Installed",
      dataIndex: "total_pwa_installed",
      key: "total_pwa_installed",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Total User Subscription to Push Notification",
      dataIndex: "total_user_notification_subscription",
      key: "total_user_notification_subscription",
      render: (text) => <div>{text}</div>,
    },
  ];

  const columnsTokenOperation = [
    {
      title: <div style={{ textAlign: "center" }}>ID</div>,
      dataIndex: "id",
      key: "id",
      render: (text) => <div style={{ textAlign: "center" }}>{text}</div>,
    },
    {
      title: <div style={{ textAlign: "center" }}>Token amount</div>,
      dataIndex: "amount",
      key: "amount",
      render: (text) => <div style={{ textAlign: "center" }}>{text * 100}</div>,
    },
    {
      title: <div style={{ textAlign: "center" }}>Price, $</div>,
      dataIndex: "price",
      key: "price",
      render: (text) => <div style={{ textAlign: "center" }}>${text || 0}</div>,
    },
    {
      title: <div style={{ textAlign: "center" }}>Type</div>,
      dataIndex: "payment_type",
      key: "payment_type",
      render: (text) => <div style={{ textAlign: "center" }}>{text}</div>,
    },
    {
      title: <div style={{ textAlign: "center" }}>Transaction Id</div>,
      dataIndex: "transaction_id",
      key: "transaction_id",
      render: (text) => (
        <div style={{ textAlign: "center" }}>{text || "–"}</div>
      ),
    },
    {
      title: <div style={{ textAlign: "center" }}>Date</div>,
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => (
        <div style={{ textAlign: "center" }}>
          {text ? dayjs(text).format("YYYY-MM-DD HH:mm") : "Never"}
        </div>
      ),
    },
  ];

  function editDetail(record) {
    setBalance(record.balance);
    setIsEdit(record.user_id);
    form.setFieldsValue({
      location: record.location,
      email: record.email,
      user_id: record.user_id,
      status: record?.status,
      balance: record.balance,
      whitelisted: record.whitelisted ? "True" : "False",
      influencer_id: record.influencer_id,
      is_settings: record.is_settings === 1,
      is_bonus_content: record.is_bonus_content === 1,
      is_statistics: record.is_statistics === 1,
      is_earnings: record.is_earnings === 1,
      is_costs: record.is_costs === 1,
      selected_influencer_id: record.selected_influencer_id,
      timezone: record.timezone,
      role_id: record.role_id,
      referral: record.referral,
      last_interaction: record.last_interaction,
    });
    showAddUserModal("edit");
  }

  useEffect(() => {
    getInfluencerData();
    getUserData();
    getSettingsData();
  }, []);

  async function fetchUserList(text) {
    const res = await customAxios.get(
      SERVER_URL + `/user?page=${1}&limit=${10}&q=${text}`
    );
    return res.data["users"].map((user) => ({
      label: `${user.user_id} ${user.email}`,
      value: user.user_id,
    }));
  }

  const getUserData = (current = 1, limit = 10, q = "") => {
    setLoading(true);
    customAxios
      .get(SERVER_URL + `/user?page=${current}&limit=${limit}&q=${q}`)
      .then(function (res) {
        const { users, influencers, role_options, total_data } = res.data;

        setData(users);
        setTotalData(total_data);
        setTotalUser(total_data.length > 0 ? total_data[0]["total_user"] : 0);
        setTotalInfluencer(influencers);
        setRoleOptions(role_options);
        setLoading(false);
      });
  };

  const getSettingsData = () => {
    customAxios.get(SERVER_URL + "/settings").then(function (res) {
      // console.log("res", res.data);
      if (res.data.length >= 1) {
        setSettings(res.data[0]);
      }
    });
  };

  const showSettingsModal = () => {
    setIsSettingsModalOpen(true);
    getSettingsData();
    // console.log("settings", settings);
  };

  const handleSettingsOk = () => {
    setIsSettingsModalOpen(false);
    const settings = form.getFieldsValue();
    // console.log("settings", settings);
    customAxios.post(SERVER_URL + "/settings", settings).then(function (res) {
      // console.log("res data", res);
      setSettings(res.data);
    });
    // console.log("set", settings);
  };

  const handleSettingsCancel = () => {
    setIsSettingsModalOpen(false);
  };

  const OpenDeleteRecordAreYouSure = (user_id) => {
    // Find the record in the array with the matching id
    const record = data.find((record) => record.id === user_id);

    // If there is a record, set the deleteActive property to true
    if (record) {
      record.deleteActive = true;
      setData([...data]);
    }
  };

  const CloseDeleteRecordAreYouSure = (user_id) => {
    // Find the record in the array with the matching id
    const record = data.find((record) => record.id === user_id);

    // If there is a record, set the deleteActive property to true
    if (record) {
      record.deleteActive = false;
      setData([...data]);
    }
  };

  const deleteRecord = async (user_id) => {
    try {
      let res = await customAxios.delete(SERVER_URL + "/user", {
        data: {
          user_id,
          page: currentPage,
          limit: limit,
        },
      });

      // console.log("data", data);
      if (res.data.error) {
        window.alert(res.data.error);
        return;
      } else {
        const { users } = res.data;
        setData(users);
      }
    } catch (error) {
      console.log("--ERROR--", error);
    }
  };

  const showAddUserModal = (action) => {
    setIsAddUserModalOpen(true);
    if (action === "add") {
      form.setFieldValue("role_id", 4);
    }
  };
  const showSendMsgModal = () => {
    setIsAddMsgModalOpen(true);
  };

  const getTokenOperationList = async (user_id) => {
    const res = await customAxios.get(
      SERVER_URL + `/user-token-operations?user_id=${user_id}`
    );
    setTokenOperationList(res.data);
  };

  const showBalanceOperationModal = async (user_id) => {
    setIsBalanceOperationModal(true);

    getTokenOperationList(user_id);
  };

  const closeBalanceOperationModal = () => {
    setIsBalanceOperationModal(false);
  };

  const handleUserOk = async () => {
    try {
      const user = form.getFieldsValue();
      user.balance = balance;
      // console.log("user", user)
      if (isEdit !== "") {
        const res = await customAxios.put(SERVER_URL + "/user", {
          ...user,
          user_id: isEdit,
          page: currentPage,
          limit: limit,
        });

        if (res.error) {
          messageApi.open({
            type: "error",
            content: res.error,
          });
        } else {
          const { users } = res.data;
          setData(users);

          messageApi.open({
            type: "success",
            content: "User saved successfully!",
          });
          setIsAddUserModalOpen(false);
          clearFormData();
        }
      } else {
        const res = await customAxios.post(SERVER_URL + "/user", {
          ...user,
          page: currentPage,
          limit: limit,
        });
        console.log("res", res);

        if (res.data.error) {
          messageApi.open({
            type: "error",
            content: res.data.error,
          });
        } else {
          const { users } = res.data;
          setData(users);

          messageApi.open({
            type: "success",
            content: "User saved successfully!",
          });
          setIsAddUserModalOpen(false);
          clearFormData();
        }
      }
    } catch (error) {
      console.log("--ERROR--", error);
      if (error.response) {
        // Try to get the error message from the server otherwise set it to a default message
        const errorMessage =
          error.response.data.error || "Something went wrong!";

        messageApi.open({
          type: "error",
          content: errorMessage,
        });
      }
    }
  };
  const handleUserCancel = () => {
    if (isEdit !== "") {
      setIsEdit("");
    }
    setIsAddUserModalOpen(false);
    clearFormData();
  };

  function clearFormData() {
    form.resetFields();
    setBalance(0);
  }

  const uploadImageHandler = async () => {
    if (!fileInput) {
      messageApi.error("Invalid file!");
      return;
    }
    try {
      setFileUploadLoading(true);

      // Create a new FormData instance
      const formData = new FormData();

      // Append the file to the FormData instance
      formData.append("file", fileInput);
      formData.append("name", fileInput.name);
      formData.append("type", fileInput.type);

      // POST the formData instance to the server
      let response = await customAxios.post(
        SERVER_URL + "/upload/url",
        formData,
        {
          headers: {
            "Content-Type": fileInput.type,
          },
        }
      );

      // The file upload is complete
      setFileUploadPath(response.data.file_path);
      messageApi.success("File upload success!");
    } catch (error) {
      console.log("--ERROR--", error);
      messageApi.open({
        type: "error",
        content: error.message,
      });
    } finally {
      setFileUploadLoading(false);
    }
  };

  const handleMsgOk = async () => {
    try {
      setSendingMessage(true);

      const formData = form.getFieldsValue();

      if (fileInput !== null && fileUploadPath === "") {
        messageApi.error("Image selected but not uploaded");
        return;
      }

      // POST the formData instance to the server
      let response = await customAxios.post(
        SERVER_URL + "/system/sendMessages",
        {
          user_ids: formData.users,
          from_influencer_id: formData.sendFromInfluencer,
          visibleInChatHistory: formData.visibleInChatHistory,
          message: formData.message,
          image_path: fileUploadPath,
        }
      );

      if (response.data.success) {
        messageApi.success("Message sent successfully!");
      } else {
        messageApi.error("Message sending failed!" + response.data.error);
      }
      setSendingMessage(false);

      setFileUploadLoading(false);
      setFileInput(null);
      setFileUploadPath("");

      /*const url = process.env.REACT_APP_SEND_MESSGE_URL;

      customAxios.get(SERVER_URL + "/system/settings").then(function (res) {
          
        let TELEGRAM_BOT_TOKEN = res.data[0].telegram_bot_token;

        formData.users.forEach((item) => {
          axios.get(url + TELEGRAM_BOT_TOKEN + `/sendMessage?chat_id=${item}&text=${formData.message}`);
        });

      });*/

      setIsAddUserModalOpen(false);
    } catch (error) {
      console.log("--ERROR--", error);
      messageApi.open({
        type: "error",
        content: error.message,
      });
      setSendingMessage(false);
    }
    setIsAddMsgModalOpen(false);
  };

  const handleMsgCancel = () => {
    setIsAddMsgModalOpen(false);
  };

  return (
    <div>
      {contextHolder}
      <div>
        <Button type="primary" onClick={() => showAddUserModal("add")}>
          Add user
        </Button>
        <Button
          type="primary"
          style={{ margin: "0 8px" }}
          onClick={() => showSendMsgModal()}
        >
          Send message
        </Button>
        {/* <DownloadCSV style={{ marginLeft: "8px" }} /> */}
        <div style={{ float: "right" }}>
          <Button type="primary" onClick={() => showSettingsModal()}>
            Settings
          </Button>
        </div>
      </div>
      <div>
        {loading && (
          <BarLoader
            color="#1677ff"
            cssOverride={{
              display: "block",
              margin: "10vh auto",
              borderColor: "red",
            }}
            size={150}
          />
        )}
        {!loading && (
          <div>
            <Table
              style={{ marginTop: "8px", marginBottom: "50px" }}
              columns={columnsTotal}
              dataSource={totalData}
              pagination={false}
              rowKey="totalId"
            />
            <div style={{ display: "flex", gap: "20px", width: "100%" }}>
              <DebounceSelect
                value={userOption}
                label={"Search User"}
                placeholder="Select user"
                fetchOptions={fetchUserList}
                onChange={(newValue) => {
                  getUserData(1, 10, newValue.value);
                  setUserOption(newValue);
                }}
                style={{
                  width: "100%",
                }}
              />
            </div>
            <Table
              style={{ marginTop: "8px" }}
              columns={columns}
              dataSource={data}
              rowKey="id"
              onChange={(pagination) => {
                setCurrentPage(pagination.current);
                setLimit(pagination.pageSize);
                getUserData(pagination.current, pagination.pageSize);
              }}
              pagination={{
                position: ["topRight", "bottomRight"],
                current: currentPage,
                pageSize: limit,
                total: totalUser,
                pageSizeOptions: [10, 20, 50, 100, 500],
              }}
            />
          </div>
        )}
      </div>

      <Modal
        title={isEdit !== "" ? "Edit user" : "Add user"}
        open={isAddUserModalOpen}
        onOk={handleUserOk}
        onCancel={handleUserCancel}
        width={1200}
      >
        <Form form={form} name="dynamic_rule" layout="vertical">
          <div style={{ display: "flex", gap: "30px" }}>
            <div style={{ width: "100%" }}>
              {isEdit !== "" && (
                <Form.Item
                  {...formItemLayout}
                  name="balance"
                  label="Set Balance"
                  rules={[
                    { required: true, message: "Please input a Balance." },
                  ]}
                >
                  <Input
                    type="number"
                    min={1}
                    step=".01"
                    value={balance}
                    placeholder="Please input a Balance"
                    onChange={(e) => setBalance(Number(e.target.value))}
                  />
                  <button
                    style={{ marginRight: "10px" }}
                    onClick={() => setBalance(balance + 1)}
                  >
                    +1
                  </button>
                  <button
                    style={{ marginRight: "10px" }}
                    onClick={() => setBalance(balance + 2)}
                  >
                    +2
                  </button>
                  <button
                    style={{ marginRight: "10px" }}
                    onClick={() => setBalance(balance + 5)}
                  >
                    +5
                  </button>
                  <button
                    style={{ marginRight: "10px" }}
                    onClick={() => setBalance(balance + 10)}
                  >
                    +10
                  </button>
                </Form.Item>
              )}
              <Form.Item
                {...formItemLayout}
                name="location"
                label="Location (Country)"
                rules={[
                  { required: false, message: "Please select your location" },
                ]}
              >
                <Select
                  name="location"
                  options={locationOptions}
                  showSearch={true}
                />
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                name="timezone"
                label="Timezone"
                rules={[
                  { required: false, message: "Please select your timezone" },
                ]}
              >
                <Select
                  name="timezone"
                  options={timezoneOptions}
                  showSearch={true}
                />
              </Form.Item>
              {isEdit.length === 0 && (
                <Form.Item
                  {...formItemLayout}
                  name="email"
                  label="E-Mail"
                  rules={[
                    { required: true, message: "Please input a E-Mail." },
                  ]}
                >
                  <Input placeholder="Please input a E-Mail." />
                  {/* <Input placeholder="Please input your telegram username." /> */}
                </Form.Item>
              )}
              {isEdit.length === 0 && (
                <Form.Item
                  {...formItemLayout}
                  name="password"
                  label="Password (min. 8 Characters)"
                  rules={[
                    { required: true, message: "Please input a Password." },
                  ]}
                >
                  <Input.Password
                    min={8}
                    placeholder="Please input a Password."
                  />
                </Form.Item>
              )}
              {isEdit !== "" && (
                <Form.Item {...formItemLayout} name="status" label="Status">
                  <Select
                    name="status"
                    options={[
                      { value: "active", label: "Active" },
                      { value: "banned", label: "Banned" },
                    ]}
                  />
                </Form.Item>
              )}
              <Form.Item
                {...formItemLayout}
                name="whitelisted"
                label="Whitelisted"
              >
                <Select
                  name="whitelisted"
                  options={[
                    { value: "True", label: "True" },
                    { value: "False", label: "False" },
                  ]}
                />
              </Form.Item>
            </div>

            <div style={{ width: "100%" }}>
              <Form.Item {...formItemLayout} name="role_id" label="User Role">
                <Select
                  name="role_id"
                  options={roleOptions}
                  rules={[{ required: true, message: "Please select role" }]}
                />
              </Form.Item>

              <Form.Item {...formItemLayout} name="referral" label="Referral">
                <Input disabled type="string" value="referral" />
              </Form.Item>

              <Form.Item
                {...formItemLayout}
                name="last_interaction"
                label="Last Interaction"
              >
                <Input disabled type="string" value="last_interaction" />
              </Form.Item>
            </div>
          </div>
        </Form>
      </Modal>

      <Modal
        title="Send Message"
        okText={sendingMessage ? "Sending..." : "OK"}
        open={isAddMsgModalOpen}
        onOk={handleMsgOk}
        onCancel={handleMsgCancel}
      >
        <Form form={form} name="dynamic_rule" layout="vertical">
          <Form.Item
            {...formItemLayout}
            name="showEmail"
            label="Show user Email in Search"
          >
            <Select
              name="showEmail"
              options={[
                { value: true, label: "True" },
                { value: false, label: "False" },
              ]}
              defaultValue={false}
              onChange={(value) => setShowUserEmails(value)}
            />
          </Form.Item>
          <Form.Item {...formItemLayout} name="users" label="Users">
            <Select
              mode="tags"
              size={"middle"}
              placeholder="Please select users"
              style={{
                width: "100%",
              }}
              options={sendMessageOptions}
            />
          </Form.Item>
          <Form.Item {...formItemLayout} name="allUsers" label="All Users">
            <Select
              name="allUsers"
              options={[
                { value: true, label: "Yes" },
                { value: false, label: "No" },
              ]}
              onChange={(value) => setSendToAll(value)}
            />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="visibleInChatHistory"
            label="Visible in Chat history"
          >
            <Select
              name="visibleInChatHistory"
              options={[
                { value: "True", label: "True" },
                { value: "False", label: "False" },
              ]}
            />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="sendFromInfluencer"
            label="Send from Influencer"
          >
            <Select
              mode="tags"
              size={"middle"}
              placeholder="Please select Influencers"
              style={{
                width: "100%",
              }}
              options={sendInfluencerMessageOptions}
            />
          </Form.Item>
          <Space.Compact block>
            <Input
              type="file"
              placeholder="Select file"
              accept="image/*"
              style={{ width: "80%" }}
              onChange={(e) => {
                if (e.target.files && e.target.files.length > 0) {
                  setFileInput(e.target.files[0]);
                }
              }}
            />
            <Button
              type="primary"
              onClick={uploadImageHandler}
              style={{ width: "20%", marginTop: "0px" }}
              loading={fileUploadLoading}
              disabled={fileUploadPath}
            >
              {fileUploadPath.length > 0 ? "Uploaded" : "Upload"}
            </Button>
          </Space.Compact>
          <Form.Item {...formItemLayout} name="message" label="Message">
            <TextArea rows={4} />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Settings"
        open={isSettingsModalOpen}
        onOk={handleSettingsOk}
        onCancel={handleSettingsCancel}
      >
        <Form form={form} name="dynamic_rule">
          <Form.Item
            {...formItemLayout}
            name="blocked_countries"
            label="Blocked Countries"
          >
            <Select
              mode="tags"
              size={"middle"}
              placeholder="Please select"
              style={{
                width: "100%",
                float: "right",
              }}
              options={countries}
              defaultValue={settings["blocked_countries"]}
            />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title={"User balance operations"}
        open={isBalanceOperationModal}
        onOk={closeBalanceOperationModal}
        onCancel={closeBalanceOperationModal}
        width={1200}
      >
        <Table
          style={{ marginTop: "8px" }}
          columns={columnsTokenOperation}
          dataSource={tokenOperationList}
          rowKey="id"
          onChange={(pagination) => {
            setCurrentPage(pagination.current);
            setLimit(pagination.pageSize);
            getUserData(pagination.current, pagination.pageSize);
          }}
          pagination={{
            position: ["topRight", "bottomRight"],
            current: currentPage,
            pageSize: 50,
            total: tokenOperationList?.length,
            pageSizeOptions: [10, 20, 50, 100],
          }}
        />
      </Modal>
    </div>
  );
};

export default User;
